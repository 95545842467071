import React, { useEffect } from 'react';
import { MapContainer, TileLayer, GeoJSON, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.fullscreen/Control.FullScreen.css';
import 'leaflet.fullscreen';

// Función para generar colores únicos basados en el número de clústeres
const generateUniqueColors = (numColors) => {
  const colors = [];
  for (let i = 0; i < numColors; i++) {
    // Genera colores en el espectro de HSL para evitar repeticiones y mantener buenos contrastes
    const hue = (i * 360 / numColors) % 360; // Cicla a través de 360 grados de color
    colors.push(`hsl(${hue}, 70%, 50%)`);
  }
  return colors;
};

const ClusterMapView = ({ data }) => {
  // Crear un array de colores únicos según el número de clústeres en los datos
  const clusters = [...new Set(data.features.map(f => f.properties.cluster))];
  const colors = generateUniqueColors(clusters.length);

  // Función para obtener el color correspondiente a un clúster
  const getColor = (cluster) => colors[cluster % colors.length];

  // Estilo de los polígonos de cada clúster
  const style = (feature) => ({
    fillColor: getColor(feature.properties.cluster),
    weight: 1,
    opacity: 1,
    color: 'white',
    fillOpacity: 0.7,
  });

  const FullscreenControl = () => {
    const map = useMap();

    useEffect(() => {
      if (!map.fullscreenControl) {
        map.fullscreenControl = L.control.fullscreen({
          position: 'topright',
          title: 'Ver en pantalla completa',
          titleCancel: 'Salir de pantalla completa',
          forceSeparateButton: true,
        }).addTo(map);
      }
    }, [map]);

    return null;
  };

  const Legend = () => {
    const map = useMap();

    useEffect(() => {
      if (!map.legendControl) {
        const legend = L.control({ position: 'bottomright' });

        legend.onAdd = function () {
          const div = L.DomUtil.create('div', 'info legend');
          
          // Estilos CSS para la leyenda
          div.style.backgroundColor = 'white';
          div.style.padding = '10px';
          div.style.borderRadius = '8px';
          div.style.boxShadow = '0 0 15px rgba(0, 0, 0, 0.2)';
          div.style.border = '1px solid #ccc';
          div.style.fontSize = '12px';
          div.style.color = 'black';

          div.innerHTML = '<h4>Simbología</h4>';
          
          // Generar una entrada para cada clúster con su respectivo color
          clusters.forEach((cluster, index) => {
            div.innerHTML +=
              `<i style="background: ${getColor(cluster)}; width: 18px; height: 18px; display: inline-block; margin-right: 8px; border: 1px solid #000;"></i> Cluster ${cluster}<br>`;
          });
          return div;
        };

        legend.addTo(map);
        map.legendControl = legend;
      }
    }, [map]);

    return null;
  };

  return (
    <MapContainer 
      center={[-33.44, -70.65]} 
      zoom={13} 
      style={{ height: '500px', width: '100%' }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="&copy; OpenStreetMap contributors"
      />
      {data && (
        <GeoJSON data={data} style={style} />
      )}
      <FullscreenControl />
      <Legend />
    </MapContainer>
  );
};

export default ClusterMapView;
