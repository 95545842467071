// src/components/Alert.js
import React from 'react';

const Alert = ({ message, type = 'info', onClose }) => {
  const alertTypeClasses = {
    info: 'alert-info',
    success: 'alert-success',
    error: 'alert-error',
    warning: 'alert-warning',
  };

  return (
    <div className={`alert ${alertTypeClasses[type]} shadow-lg`}>
      <div>
        <span>{message}</span>
      </div>
      <button onClick={onClose} className="btn btn-sm btn-circle btn-ghost">
        ✕
      </button>
    </div>
  );
};

export default Alert;
