import React, { useState } from 'react';

const UploadImageForm = ({ onUpload, onFilter }) => {
  const [files, setFiles] = useState([]);


  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (files.length > 0) {
      onUpload(files);
    } else {
      alert('Por favor, selecciona al menos un archivo para cargar.');
    }
  };


  return (
    <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
      <input
        type="file"
        accept=".jpg,.jpeg,.png"
        multiple
        onChange={handleFileChange}
        className="text-white"
      />
      <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Cargar y procesar
      </button>
    </form>
  );
};

export default UploadImageForm;
