import React, { useEffect } from 'react';
import axios from 'axios';

export default function LandingPage({ setView }) {
  useEffect(() => {
    axios.post('https://backend-map.azurewebsites.net/reset_grid')
      .then(response => {
        console.log(response.data.message);
      })
      .catch(error => {
        console.error('Error resetting grid file:', error);
      });
  }, []);

  return (
    <div className="min-h-screen w-full bg-gray-100 flex flex-col">
      <header className="bg-white shadow-sm w-full">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
        <div className="font-bold text-xl text-blue-600">MapInsight</div>
          <nav>
            <ul className="flex space-x-4">
              <li>
                <button
                  onClick={() => setView('map')}
                  className="bg-blue-600 text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  Ir a la app
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <main className="flex-grow flex items-center justify-center w-full">
        <div className="max-w-7xl w-full px-4 sm:px-6 lg:px-8 py-12">
          <div className="bg-[#1e2a3b] rounded-lg overflow-hidden shadow-xl">
            <div className="p-8 sm:p-12 flex flex-col md:flex-row items-center justify-between">
              <div className="text-white max-w-2xl">
                <h1 className="text-4xl font-bold mb-4">Empodera tu análisis geoespacial con precisión</h1>
                <p className="mb-6">Accede a potentes herramientas de análisis para obtener información valiosa a partir de datos espaciales de forma sencilla.</p>
                <button
                  className="bg-white text-blue-600 px-6 py-2 rounded-full font-medium"
                  onClick={() => setView('map')}
                >
                  Ir a la app
                </button>
              </div>
              <div className="mt-8 md:mt-0">
                <img src="/mapinsight_logo.svg" alt="MapInsight Logo" className="w-48 h-48 svg-white" />
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer className="bg-white w-full">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 flex flex-col sm:flex-row justify-between items-center">
          <div className="text-sm text-gray-500 mb-4 sm:mb-0">
            © 2024 MapInsight. All rights reserved.
          </div>
          <div className="flex space-x-6">
            <img src="/Logo_UTFSM.svg" alt="Partner Logo 1" className="w-12 h-12" />
            <img src="/trace.svg" alt="Partner Logo 2" className="w-24 h-12" />
          </div>
        </div>
      </footer>
    </div>
  );
}
