import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';

const MapView = () => {
  const [geojsonData, setGeojsonData] = useState(null);

  useEffect(() => {
    const fetchGeoJSON = async () => {
      try {
        const response = await axios.get('https://backend-map.azurewebsites.net/grid'); // Asegúrate de que esta URL sea correcta.
        setGeojsonData(response.data);
      } catch (error) {
        console.error('Error loading GeoJSON data:', error);
      }
    };
    fetchGeoJSON();
  }, []);

  const onEachFeature = (feature, layer) => {
    layer.bindPopup(`<p>${feature.properties.id}</p>`);
  };

  return (
    <MapContainer center={[-33.45, -70.66]} zoom={13} style={{ height: '100vh', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {geojsonData && (
        <GeoJSON
          data={geojsonData}
          onEachFeature={onEachFeature}
          style={() => ({
            color: '#4a83ec',
            weight: 0.5,
            fillColor: '#1a1d62',
            fillOpacity: 0.4,
          })}
        />
      )}
    </MapContainer>
  );
};

export default MapView;
