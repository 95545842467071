// src/components/Navbar.js
import React from 'react';

const Navbar = ({ currentView, setView }) => (
  <nav className="bg-gray-800 p-4 rounded mb-4">
    <ul className="flex space-x-4">
      <li>
        <button 
          className={`text-white ${currentView === 'map' ? 'font-bold' : 'text-gray-400 hover:text-white'}`}
          onClick={() => setView('map')}
        >
          Vista de mapa
        </button>
      </li>
      <li>
        <button 
          className={`text-white ${currentView === 'data' ? 'font-bold' : 'text-gray-400 hover:text-white'}`}
          onClick={() => setView('data')}
        >
          Vista dataframe
        </button>
      </li>
      <li>
        <button 
          className={`text-white ${currentView === 'ml' ? 'font-bold' : 'text-gray-400 hover:text-white'}`}
          onClick={() => setView('ml')}
        >
          Machine Learning
        </button>
      </li>
      <li>
        <button 
          className={`text-white ${currentView === 'images' ? 'font-bold' : 'text-gray-400 hover:text-white'}`}
          onClick={() => setView('images')}
        >
          Imágenes
        </button>
      </li>
      <li>
        <button 
          className={`text-white ${currentView === 'naive' ? 'font-bold' : 'text-gray-400 hover:text-white'}`}
          onClick={() => setView('naive')}
        >
          Naive Bayes
        </button>
      </li>
    </ul>
  </nav>
);

export default Navbar;
