import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import UploadForm from './components/UploadForm';
import UploadImageForm from './components/UploadImageForm';
import MapView from './components/MapView';
import DataTable from './components/DataTable';
import ClusterMapView from './components/ClusterMapView';
import ImageMapView from './components/ImageMapView';
import NaiveBayesMapView from './components/NaiveBayesMapView';
import LandingPage from './components/LandingPage';
import Alert from './components/Alert';
import Spinner from './components/Spinner'; 
import axios from 'axios';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Corrige la ruta de los íconos de Leaflet
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

function App() {
  const [currentView, setView] = useState('landing');
  const [data, setData] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [availableAttributes, setAvailableAttributes] = useState([]);
  const [geoData, setGeoData] = useState(null);
  const [numClusters, setNumClusters] = useState(3);
  const [mapKey, setMapKey] = useState(0);
  const [imageData, setImageData] = useState([]);
  const [filteredImages, setFilteredImages] = useState([]);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState('info');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  const showAlert = (message, type = 'info') => {
    setAlertMessage(message);
    setAlertType(type);
    setTimeout(() => {
      setAlertMessage(null);
    }, 3000);
  };

  const handleUpload = (files) => {
    const formData = new FormData();
    Array.from(files).forEach((file) => {
      formData.append('geofile', file);
    });
  
    setIsLoading(true); // Mostrar el spinner de carga
  
    axios.post('https://backend-map.azurewebsites.net/upload', formData)
      .then(() => {
        fetchData();
        showAlert('Archivo subido y procesado exitosamente.', 'success');
      })
      .catch((error) => {
        console.error('Error uploading files:', error);
        showAlert('Error al subir los archivos.', 'error');
      })
      .finally(() => {
        setIsLoading(false); // Ocultar el spinner de carga
      });
  };
  

  const handleImageUpload = (files) => {
    const formData = new FormData();
    Array.from(files).forEach((file) => {
      formData.append('image', file);
    });
  
    setIsLoading(true); // Mostrar el spinner de carga
  
    axios.post('https://backend-map.azurewebsites.net/upload_and_detect', formData)
      .then((response) => {
        if (response.data.status === 'success') {
          const { lat, lon, annotated_image_path, timestamp } = response.data;
          const newImageData = [
            ...imageData,
            {
              lat,
              lon,
              annotated_image_path,
              timestamp: timestamp || new Date().toISOString().split('T')[0]
            }
          ];
          setImageData(newImageData);
          setFilteredImages(newImageData);
          showAlert('Imágenes cargadas y procesadas exitosamente.', 'success');
        } else {
          showAlert(`Error: ${response.data.error}`, 'error');
        }
      })
      .catch((error) => {
        console.error('Error uploading images:', error);
        showAlert('Error al subir las imágenes.', 'error');
      })
      .finally(() => {
        setIsLoading(false); // Ocultar el spinner de carga
      });
  };
  
  

  const fetchData = () => {
    axios.get('https://backend-map.azurewebsites.net/grid')
      .then((response) => {
        const features = response.data.features.map((feature) => feature.properties);
        setData(features);
        setGeoData(response.data);
        setMapKey((prevKey) => prevKey + 1);

        if (features.length > 0) {
          setAvailableAttributes(Object.keys(features[0]));
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const fetchNaiveBayesData = () => {
    axios.get('https://backend-map.azurewebsites.net/run_naive_bayes')
      .then((response) => {
        if (response.data.error) {
          showAlert(response.data.error, 'error');
        } else {
          setGeoData(response.data);
          setMapKey((prevKey) => prevKey + 1);
          showAlert("Naive Bayes ejecutado y datos actualizados.", 'success');
        }
      })
      .catch((error) => {
        console.error('Error fetching Naive Bayes data:', error);
        showAlert("Ocurrió un error al ejecutar Naive Bayes.", 'error');
      });
  };

  useEffect(() => {
    if (currentView === 'data') {
      fetchData();
    }
  }, [currentView]);

  const handleAttributeChange = (event) => {
    const { options } = event.target;
    const selected = Array.from(options)
      .filter(option => option.selected)
      .map(option => option.value);

    setSelectedAttributes(selected);
  };

  const handleClustering = () => {
    if (selectedAttributes.length === 0) {
      showAlert("Por favor, selecciona al menos un atributo para realizar el clustering.", 'warning');
      return;
    }
  
    axios.post('https://backend-map.azurewebsites.net/cluster', {
      attributes: selectedAttributes,
      clusters: numClusters
    })
    .then(() => {
      fetchData();
      setMapKey((prevKey) => prevKey + 1);
      showAlert("Clustering completado con éxito.", 'success');
    })
    .catch((error) => {
      console.error('Error realizando clustering:', error);
      const errorMessage = error.response?.data?.error || 'Ocurrió un error al realizar el clustering.';
      showAlert(errorMessage, 'error');
    });
  };
  

  const handleFilterDates = () => {
    if (startDate && endDate) {
      const filtered = imageData.filter((image) => {
        if (!image.timestamp) return false; // Ignorar imágenes sin timestamp
        const imageDate = new Date(image.timestamp.split(' ')[0].replace(/:/g, '-'));
        return imageDate >= new Date(startDate) && imageDate <= new Date(endDate);
      });
      setFilteredImages(filtered);
    } else {
      setFilteredImages(imageData);
    }
  };
  

  const renderView = () => {
    switch (currentView) {
      case 'landing':
        return <LandingPage setView={setView} />;
      case 'map':
        return (
          <div className="lg:w-2/3 p-4 bg-gray-800 rounded shadow h-full">
            <MapView data={geoData} key={mapKey} />
          </div>
        );
      case 'data':
        return (
          <div className="p-4 bg-gray-800 rounded shadow h-full">
            <h2 className="text-2xl font-bold mb-4">Vista de Dataframe</h2>
            <DataTable data={data} />
          </div>
        );
      case 'ml':
        return (
          <div className="flex flex-col lg:flex-row gap-4 h-full w-full">
            <div className="lg:w-1/4 p-4 bg-gray-800 rounded shadow h-full flex-grow">
              <h2 className="text-2xl font-bold mb-4">Configuración de Clustering</h2>
              <div className="mb-4">
                <label className="block text-gray-300 mb-2">Selecciona los atributos:</label>
                <select
                  multiple
                  value={selectedAttributes}
                  onChange={handleAttributeChange}
                  className="w-full p-2 bg-gray-700 rounded text-white"
                >
                  {availableAttributes.map((attr) => (
                    <option key={attr} value={attr}>
                      {attr}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-gray-300 mb-2">Número de clusters:</label>
                <input
                  type="number"
                  value={numClusters}
                  onChange={(e) => setNumClusters(parseInt(e.target.value))}
                  className="w-full p-2 bg-gray-700 rounded text-white"
                  min="2"
                />
              </div>
              <button
                onClick={handleClustering}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Iniciar Clustering
              </button>
            </div>
            <div className="lg:w-3/4 p-4 bg-gray-800 rounded shadow h-full flex-grow">
              {geoData ? (
                <ClusterMapView data={geoData} key={mapKey} />
              ) : (
                <p className="text-gray-300">Cargando datos del mapa...</p>
              )}
            </div>
          </div>
        );
      case 'images':
        return (
          <div className="flex flex-col lg:flex-row gap-4 h-full w-full">
            <div className="lg:w-1/4 p-4 bg-gray-800 rounded shadow h-full flex-grow">
              <h2 className="text-2xl font-bold mb-4">Carga de Imágenes</h2>
              <UploadImageForm onUpload={handleImageUpload} />
              <div className="mt-4">
                <label className="block text-gray-300 mb-2">Fecha de inicio:</label>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="w-full p-2 bg-gray-700 rounded text-white"
                />
              </div>
              <div className="mt-4">
                <label className="block text-gray-300 mb-2">Fecha de fin:</label>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="w-full p-2 bg-gray-700 rounded text-white"
                />
              </div>
              <button
                onClick={handleFilterDates}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
              >
                Filtrar por Fecha
              </button>
            </div>
            <div className="lg:w-3/4 p-4 bg-gray-800 rounded shadow h-full flex-grow">
              <h2 className="text-2xl font-bold mb-4">Visualización de Imágenes</h2>
              <ImageMapView imageData={filteredImages} key={mapKey} />
            </div>
          </div>
        );
      case 'naive':
        return (
          <div className="p-4 bg-gray-800 rounded shadow h-full w-full">
            <h2 className="text-2xl font-bold mb-4">Naive Bayes - Mapa de Probabilidad</h2>
            <button
              onClick={fetchNaiveBayesData}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
            >
              Ejecutar Naive Bayes
            </button>
            {geoData ? (
              <NaiveBayesMapView data={geoData} key={mapKey} />
            ) : (
              <p className="text-gray-300">Cargando datos de Naive Bayes...</p>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-5">
      {isLoading && <Spinner />} {/* Muestra el spinner mientras `isLoading` sea true */}
      {alertMessage && (
        <div className="fixed top-4 right-4">
          <Alert message={alertMessage} type={alertType} onClose={() => setAlertMessage(null)} />
        </div>
      )}
      {currentView === 'landing' ? (
        <LandingPage setView={setView} />
      ) : (
        <div className="container mx-auto h-full">
          <h1 className="text-3xl font-bold mb-4 text-center">MapInsight</h1>
          <Navbar currentView={currentView} setView={setView} />
          <div className="flex flex-col lg:flex-row gap-4 h-full">
            {currentView !== 'ml' && currentView !== 'images' && currentView !== 'naive' && (
              <div className="lg:w-1/3 p-4 bg-gray-800 rounded shadow h-full">
                <UploadForm onUpload={handleUpload} />
              </div>
            )}
            {renderView()}
          </div>
        </div>
      )}
    </div>
  );  
}

export default App;
