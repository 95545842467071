import React, { useEffect } from 'react';
import { MapContainer, TileLayer, GeoJSON, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.fullscreen/Control.FullScreen.css';
import 'leaflet.fullscreen';

const NaiveBayesMapView = ({ data }) => {
  const getColor = (probability) => {
    return probability > 0.95 ? '#800026' :
           probability > 0.90 ? '#bd0026' :
           probability > 0.85 ? '#e31a1c' :
           probability > 0.80 ? '#fc4e2a' :
           probability > 0.75 ? '#fd8d3c' :
           probability > 0.70 ? '#feb24c' :
           probability > 0.65 ? '#fed976' :
           probability > 0.60 ? '#ffeda0' :
           probability > 0.55 ? '#ffffcc' :
           probability > 0.50 ? '#d9f0a3' :
           probability > 0.45 ? '#addd8e' :
           probability > 0.40 ? '#78c679' :
           probability > 0.35 ? '#41ab5d' :
           probability > 0.30 ? '#238443' :
           probability > 0.25 ? '#006837' :
           probability > 0.20 ? '#004529' :
           probability > 0.15 ? '#003300' :
           probability > 0.10 ? '#002200' :
           probability > 0.05 ? '#001100' :
                                '#000000';
  };

  const style = (feature) => ({
    fillColor: getColor(feature.properties.probability),
    weight: 1,
    opacity: 1,
    color: 'white',
    fillOpacity: 0.7,
  });

  const FullscreenControl = () => {
    const map = useMap();

    useEffect(() => {
      if (!map.fullscreenControl) {
        map.fullscreenControl = L.control.fullscreen({
          position: 'topright',
          title: 'Ver en pantalla completa',
          titleCancel: 'Salir de pantalla completa',
          forceSeparateButton: true,
        }).addTo(map);
      }
    }, [map]);

    return null;
  };

  const Legend = () => {
    const map = useMap();

    useEffect(() => {
      const legend = L.control({ position: 'bottomright' });

      legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'info legend');
        div.style.backgroundColor = 'white';
        div.style.padding = '5px'; // Reduce el padding para hacer la leyenda más compacta
        div.style.borderRadius = '5px';
        div.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.2)';
        div.style.border = '1px solid #ccc';
        div.style.fontSize = '10px'; // Disminuir el tamaño de la fuente para que la leyenda sea más compacta
        div.style.color = 'black';

        const grades = [
          0, 0.05, 0.10, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 
          0.45, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 
          0.90, 0.95, 1
        ];
        const labels = grades.slice(0, -1).map(
          (grade, index) =>
            `<i style="background:${getColor(grade + 0.01)}; width: 12px; height: 12px; display: inline-block; margin-right: 4px;"></i> 
            ${grade.toFixed(2)} - ${grades[index + 1].toFixed(2)}`
        ).join('<br>');

        div.innerHTML = `<h4 style="margin-bottom: 5px;">Probabilidad de Comercio</h4>${labels}`;
        return div;
      };

      legend.addTo(map);
      return () => map.removeControl(legend);
    }, [map]);

    return null;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <MapContainer 
        center={[-33.44, -70.65]} 
        zoom={13} 
        style={{ height: '500px', width: '100%' }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; OpenStreetMap contributors"
        />
        {data && (
          <GeoJSON data={data} style={style} />
        )}
        <FullscreenControl />
        <Legend />
      </MapContainer>
    </div>
  );
};

export default NaiveBayesMapView;
