// src/components/ImageMapView.js
import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.fullscreen/Control.FullScreen.css'; // Importa los estilos de fullscreen
import 'leaflet.fullscreen';

// Configurar íconos de Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const ImageMapView = ({ imageData = [], center = [-33.44, -70.65], zoom = 13 }) => {
  const FullscreenControl = () => {
    const map = useMap();

    useEffect(() => {
      if (!map.fullscreenControl) {
        map.fullscreenControl = L.control.fullscreen({
          position: 'topright',
          title: 'Ver en pantalla completa',
          titleCancel: 'Salir de pantalla completa',
          forceSeparateButton: true,
        }).addTo(map);
      }
    }, [map]);

    return null;
  };

  // Obtén la última imagen subida
  const latestImage = imageData.length > 0 ? imageData[imageData.length - 1] : null;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <MapContainer 
        center={center} 
        zoom={zoom} 
        style={{ height: '500px', width: '100%' }} // Ajusta el tamaño según sea necesario
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; OpenStreetMap contributors"
        />
        {imageData.map((image, index) => (
          <Marker key={index} position={[image.lat || center[0], image.lon || center[1]]}>
            <Popup>
              Ubicación de la imagen procesada.
            </Popup>
          </Marker>
        ))}
        <FullscreenControl />
      </MapContainer>
      {latestImage && latestImage.annotated_image_path && (
        <div className="p-4 bg-gray-800 rounded shadow h-full flex-grow">
          <h2 className="text-2xl font-bold mb-4">Imagen Anotada</h2>
          <div className="flex justify-center items-center">
            <div className="bg-gray-900 p-4 rounded-lg shadow-lg">
              <img
                src={`https://backend-map.azurewebsites.net/${latestImage.annotated_image_path}`}
                alt="Imagen Anotada"
                className="max-w-full h-auto rounded-md border border-gray-700"
                style={{ boxShadow: '0 10px 15px rgba(0, 0, 0, 0.3)' }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageMapView;
